<template>
  <div class="about">
    <!--====== PAGE TITLE PART START ======-->

    <div class="page-title-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="page-title-item text-center">
              <h2 class="title">About Us</h2>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="index.php">Kibbutz Tech</a>
                  </li>
                </ol>
              </nav>
            </div>
            <!-- page title -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>

    <!--====== PAGE TITLE PART ENDS ======-->

    <!--====== ABOUT INTRO PART START ======-->

    <div class="about-intro-area pt-120">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class>
              <img
                style="height:400px; margin-top: -80px; "
                src="/assets/images/AboutKibbutz.svg"
                alt="Blog"
              />
              <!-- <img src="src/assets/images/aboutmain.png" alt /> -->
              <!-- <a class="video-popup" href="http://bit.ly/2VuPnrx"></a> -->
            </div>
          </div>
          <div class="col-lg-6">
            <div class="intri-content">
              <span>ABOUT KIBBUTZ</span>
              <p class="text-1">We've Been Thriving for 8 Years In The Developer Area.</p>
              <p
                class="text-2"
              >We've been thriving in the developer space for the past six years. The Kibbutz Developers Company offers a wide range of technical solutions to professionals and students, including website and application development, web & graphic design, digital marketing, and unique services such as bulk SMS & emails, toll-free numbers, and missed call alerts.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--======End ABOUT INTRO PART START ======-->

    <!--====== ABOUT EXPERIENCE PART START ======-->
    <div class="about-intro-area pt-130" style=" background-color: #fff;">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="intri-content">
              <span>CHOOSING US IS BLISS</span>
              <p class="text-1">We have highly skilled engineers with excellent technical knowledge.</p>
              <p
                class="text-2"
              >We have highly qualified engineers with in-depth technical knowledge. We have extensive experience with the most recent software standards. We've built a vast knowledge base that we use to develop solutions that fit the needs, expectations, and budgets of our clients. We are delighted that we are still servicing our very first business client because we put our clients at the centre of all we do.</p>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="ml-5 pl-5">
              <img
                style="height:400px; margin-top: -80px; "
                src="/assets/images/CHOOSING.svg"
                alt="Blog"
              />
              <!-- <img src="src/assets/images/aboutmain.png" alt /> -->
              <!-- <a class="video-popup" href="http://bit.ly/2VuPnrx"></a> -->
            </div>
          </div>
        </div>
      </div>
      <!-- container -->
    </div>

    <!--====== ABOUT EXPERIENCE PART ENDS ======-->
    <!--====== ABOUT INTRO PART START ======-->

    <div class="about-intro-area pt-120">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class>
              <img
                style="height:400px; margin-top: -80px; "
                src="/assets/images/about.png"
                alt="Blog"
              />
              <!-- <img src="src/assets/images/aboutmain.png" alt /> -->
              <!-- <a class="video-popup" href="http://bit.ly/2VuPnrx"></a> -->
            </div>
          </div>
          <div class="col-lg-6">
            <div class="intri-content">
              <span>KIBBUTZ HISTORY</span>
              <!-- <p class="text-1">We've Been Thriving for 6 Years In The Developer Area.</p> -->
              <p class="text-2">
                Our story began in 2015, and we now have the opportunity of connecting with
                millions of people every day through amazing products and services in countries
                all over the world. We develop and deploy digital solutions to address important
                business concerns at Kibbutz.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--======End ABOUT INTRO PART START ======-->

    <!--====== ABOUT EXPERIENCE PART START ======-->
    <div class="about-intro-area pt-130" style=" background-color: #fff;">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="intri-content">
              <span>KIBBUTZ MISSION</span>
              <!-- <p class="text-1">We have highly skilled engineers with excellent technical knowledge.</p> -->
              <p class="text-2">
                Our website and app development should be succinct and eloquent, with a lot of
                depth and effect. Choose your words carefully—avoid challenges, hollow
                phrases, or mission statements that may apply to a variety of businesses and we
                adhere to that. Our mission is to help our customers' businesses grow by
                providing creative design, development, and market-defining high-quality
                solutions that add value and provide a reliable competitive advantage. To achieve
                effective results, we combine in-depth functional knowledge, international
                standards, and holistic people.
              </p>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="ml-5 pl-5">
              <img
                style="height:400px; margin-top: -80px; "
                src="/assets/images/MISSION.svg"
                alt="Blog"
              />
              <!-- <img src="src/assets/images/aboutmain.png" alt /> -->
              <!-- <a class="video-popup" href="http://bit.ly/2VuPnrx"></a> -->
            </div>
          </div>
        </div>
      </div>
      <!-- container -->
    </div>

    <!--====== ABOUT EXPERIENCE PART ENDS ======-->

    <!--====== ABOUT INTRO PART START ======-->

    <div class="about-intro-area pt-120">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class>
              <img
                style="height:400px; margin-top: -80px; "
                src="/assets/images/VISION.svg"
                alt="Blog"
              />
              <!-- <img src="src/assets/images/aboutmain.png" alt /> -->
              <!-- <a class="video-popup" href="http://bit.ly/2VuPnrx"></a> -->
            </div>
          </div>
          <div class="col-lg-6">
            <div class="intri-content">
              <span>KIBBUTZ VISION</span>
              <!-- <p class="text-1">We've Been Thriving for 6 Years In The Developer Area.</p> -->
              <p class="text-2">
                We understand that no two customer landscapes are the same. As a result, our
                methodology includes a specific solution tailored to the clients' needs. In order
                to align IT goals with the overall vision of the organization, we offer a phased
                approach toward your business drivers. Our key goal is to continuously improve
                and establish ourselves as a top performer in this cutthroat worldwide market.
                Fortunately, we were able to put together a team of experts that can form and
                mould their combined experiences and who all have exceptional talent that can
                help your firm advance. To make Kibbutz the best provider of software
                development and technology services in the country and worldwide. In an
                oncoming era of technology, we want to create an environment that denotes IT
                abundance, with innovative and stylish web development and software solutions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--======End ABOUT INTRO PART START ======-->

    <!-- <div class="about-intro-area pt-120">
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="intri-content">
              <h3 class="text-1">KIBBUTZ HISTORY</h3>
              <p
                class="text-2"
              >Our story began in 2014, and we now have the opportunity of connecting with millions of people every day through amazing products and services in countries all over the world. We develop and deploy digital solutions to address important business concerns at Kibbutz.</p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="intri-content">
              <h3 class="text-1">Kibbutz Mission</h3>
              <p
                class="text-2"
              >Our website and app development should be succinct and eloquent, with a lot of depth and effect. Choose your words carefully—avoid challenges, hollow phrases, or mission statements that may apply to a variety of businesses and we adhere to that. Our mission is to help our customers' businesses grow by providing creative design, development, and market-defining high-quality solutions that add value and provide a reliable competitive advantage. To achieve effective results, we combine in-depth functional knowledge, international standards, and holistic people.</p>
              Be clear and specific, and you’ll highlight the core of what makes your venture unique and worthwhile.
            </div>
          </div>
          <div class="col-lg-4">
            <div class="intri-content">
              <h3 class="text-1">Kibbutz Vision</h3>
              <p
                class="text-2"
              >We understand that no two customer landscapes are the same. As a result, our methodology includes a specific solution tailored to the clients' needs. In order to align IT goals with the overall vision of the organization, we offer a phased approach toward your business drivers. Our key goal is to continuously improve and establish ourselves as a top performer in this cutthroat worldwide market. Fortunately, we were able to put together a team of experts that can form and mould their combined experiences and who all have exceptional talent that can help your firm advance. To make Kibbutz the best provider of software development and technology services in the country and worldwide. In an oncoming era of technology, we want to create an environment that denotes IT abundance, with innovative and stylish web development and software solutions</p>
            </div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>


<script>
export default {
  components: {},
  props: {},
  data() {
    return {};
  },
  mounted() {
    // console.log("this.$route.params",this.$route.params.name)
    window.scrollTo(0, 0);
  }
};
</script>